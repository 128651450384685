import { Address } from 'viem'

import { HistoryTab } from '../constants/history'
import { MarketId } from './markets'
import { OrderQueryParam } from './query'
import { CollateralId } from './tokens'

export enum PageId {
  CreateWallet = 'CreateWallet',
  Landing = 'Landing',
  TermsOfUse = 'TermsOfUse',
  PrivacyPolicy = 'PrivacyPolicy',
  Options = 'Options',
  Perps = 'Perps',
  Spot = 'Spot',
  Portfolio = 'Portfolio',
  Home = 'Home',
  History = 'History',
  Settings = 'Settings',
  Balances = 'Balances',
  Developers = 'Developers',
  Alpha = 'alpha',
  Stats = 'Stats',
  Airdrop = 'Airdrop',
  PointsLeaderboard = 'PointsLeaderboard',
  TradersLeaderboard = 'TradersLeaderboard',
  ReferralsLeaderboard = 'ReferralsLeaderboard',
  Earn = 'Earn',
  Migration = 'Migration',
  Rounds = 'Rounds',
  Vault = 'Vault',
  Invites = 'Invites',
  Borrow = 'Borrow',
  LendCollateral = 'LendCollateral',
  Rfq = 'Rfq',
  Partners = 'Partners',
  Positions = 'Positions',
  Amberdata = 'Amberdata',
  User = 'User',
  Trades = 'Trades',
}

export type PageArgsMap = {
  [PageId.Landing]: undefined
  [PageId.TermsOfUse]: undefined
  [PageId.PrivacyPolicy]: undefined
  [PageId.Options]:
    | {
        marketId?: MarketId
        order?: OrderQueryParam
        expiry?: Date
      }
    | undefined
  [PageId.Perps]:
    | {
        marketId?: MarketId
        order?: OrderQueryParam
      }
    | undefined
  [PageId.Spot]:
    | {
        marketId?: CollateralId
        size?: number
        limitPrice?: number
      }
    | undefined
  [PageId.Portfolio]: undefined
  [PageId.Home]: undefined
  [PageId.History]: {
    tab?: HistoryTab
  }
  [PageId.Settings]: undefined
  [PageId.CreateWallet]: undefined
  [PageId.Alpha]: undefined
  [PageId.Developers]: undefined
  [PageId.Stats]: undefined
  [PageId.Airdrop]: {
    code?: string
  }
  [PageId.PointsLeaderboard]: undefined
  [PageId.Balances]: undefined
  [PageId.Earn]: undefined
  [PageId.Vault]: {
    id: string
  }
  [PageId.Migration]: undefined
  [PageId.Rounds]: undefined
  [PageId.Invites]: undefined
  [PageId.Borrow]: undefined
  [PageId.LendCollateral]: {
    collateral: CollateralId
  }
  [PageId.Rfq]: undefined
  [PageId.Partners]: undefined
  [PageId.Positions]: undefined
  [PageId.Amberdata]: undefined
  [PageId.TradersLeaderboard]: undefined
  [PageId.User]: {
    address: Address
  }
  [PageId.Trades]: undefined
  [PageId.ReferralsLeaderboard]: undefined
}

export type Pathname = `/${string}`

type PageArgsWithPageMap<M extends Record<string, any>> = {
  [Key in keyof M]: M[Key] extends undefined
    ? {
        page: Key
      }
    : {
        page: Key
      } & M[Key]
}
export type PagePathArgs<T extends PageId> = PageArgsWithPageMap<PageArgsMap>[T]
