import { CollateralId } from './tokens'

export enum MarketId {
  ETH = 'ETH',
  BTC = 'BTC',
  SOL = 'SOL',
  DOGE = 'DOGE',
  AAVE = 'AAVE',
  ARB = 'ARB',
  BNB = 'BNB',
  NEAR = 'NEAR',
  OP = 'OP',
  PEPE = 'PEPE',
  SUI = 'SUI',
  TIA = 'TIA',
  WIF = 'WIF',
  WLD = 'WLD',
}

export type Market = MarketData & MarketConfig

export type MarketData = {
  marketId: MarketId
  spotPrice: number
  spotPrice24hAgo: number
  spotPrice24hChange: number
  spotPrice24hPctChange: number
}

export type MarketConfig = {
  marketId: MarketId
  name: string
  collateral: CollateralId | undefined
  perpIsActive: boolean
  optionIsActive: boolean
  showInMarketList: boolean
}

export const marketsConfig: Record<MarketId, MarketConfig> = {
  [MarketId.ETH]: {
    marketId: MarketId.ETH,
    name: 'Ethereum',
    collateral: CollateralId.ETH,
    perpIsActive: true,
    optionIsActive: true,
    showInMarketList: true,
  },
  [MarketId.BTC]: {
    marketId: MarketId.BTC,
    name: 'Bitcoin',
    collateral: CollateralId.BTC,
    perpIsActive: true,
    optionIsActive: true,
    showInMarketList: true,
  },
  [MarketId.SOL]: {
    marketId: MarketId.SOL,
    name: 'Solana',
    collateral: undefined,
    perpIsActive: true,
    optionIsActive: false,
    showInMarketList: true,
  },
  [MarketId.DOGE]: {
    marketId: MarketId.DOGE,
    name: 'Dogecoin',
    collateral: undefined,
    perpIsActive: true,
    optionIsActive: false,
    showInMarketList: true,
  },
  [MarketId.AAVE]: {
    marketId: MarketId.AAVE,
    name: 'Aave',
    collateral: undefined,
    perpIsActive: true,
    optionIsActive: false,
    showInMarketList: true,
  },
  [MarketId.ARB]: {
    marketId: MarketId.ARB,
    name: 'Arbitrum',
    collateral: undefined,
    perpIsActive: true,
    optionIsActive: false,
    showInMarketList: true,
  },
  [MarketId.BNB]: {
    marketId: MarketId.BNB,
    name: 'BNB',
    collateral: undefined,
    perpIsActive: true,
    optionIsActive: false,
    showInMarketList: true,
  },
  [MarketId.NEAR]: {
    marketId: MarketId.NEAR,
    name: 'Near Protocol',
    collateral: undefined,
    perpIsActive: true,
    optionIsActive: false,
    showInMarketList: true,
  },
  [MarketId.OP]: {
    marketId: MarketId.OP,
    name: 'Optimism',
    collateral: undefined,
    perpIsActive: true,
    optionIsActive: false,
    showInMarketList: true,
  },
  [MarketId.PEPE]: {
    marketId: MarketId.PEPE,
    name: 'Pepe',
    collateral: undefined,
    perpIsActive: true,
    optionIsActive: false,
    showInMarketList: true,
  },
  [MarketId.SUI]: {
    marketId: MarketId.SUI,
    name: 'Sui',
    collateral: undefined,
    perpIsActive: true,
    optionIsActive: false,
    showInMarketList: true,
  },
  [MarketId.TIA]: {
    marketId: MarketId.TIA,
    name: 'Celestia',
    collateral: undefined,
    perpIsActive: true,
    optionIsActive: false,
    showInMarketList: true,
  },
  [MarketId.WIF]: {
    marketId: MarketId.WIF,
    name: 'dogwifhat',
    collateral: undefined,
    perpIsActive: true,
    optionIsActive: false,
    showInMarketList: true,
  },
  [MarketId.WLD]: {
    marketId: MarketId.WLD,
    name: 'Worldcoin',
    collateral: undefined,
    perpIsActive: true,
    optionIsActive: false,
    showInMarketList: true,
  },
}

export const EMPTY_MARKETS: Record<MarketId, Market> = Object.values(MarketId).reduce(
  (acc, marketId) => {
    const marketData: Market = {
      ...marketsConfig[marketId],
      marketId,
      spotPrice: 0,
      spotPrice24hAgo: 0,
      spotPrice24hChange: 0,
      spotPrice24hPctChange: 0,
    }
    acc[marketId] = marketData
    return acc
  },
  {} as Record<MarketId, Market>
)

export const DEFAULT_MARKET_ID = MarketId.ETH

export const ONLY_SHOW_EXPIRIES: Record<MarketId, string[]> = Object.values(MarketId).reduce(
  (acc, market) => {
    acc[market] = []
    return acc
  },
  {} as Record<MarketId, string[]>
)
